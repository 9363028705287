import React, { useState, useEffect, useCallback } from 'react';
import Head from 'next/head';

import { useRouter } from 'next/router';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import LayoutContainer from 'components/layout/LayoutContainer';
import MembershipContent from 'components/membership/MembershipContent';
import { SpinnerV, SnackBar } from 'components/widgets';
import { registryContent } from 'components/utils/Membership';
import Validations from 'components/utils/Validations';
import { _calculateAge } from 'components/utils/CalculateAge';
import SigningFormEmail from 'components/membership/SigningFormEmail';

import Common from 'services/common';
import BaseTitular from 'components/utils/BaseTitular';
import TagManager from 'react-gtm-module';
import SignSocial from './signSocial';
import {
	loadRecaptchaV3,
	getTokenReacaptcha
} from 'components/utils/recaptchaV3';
import Divider from 'components/divider';
import useInterval from 'components/hooks/useInterval';
import BaseBeneficiary from '../../src/components/utils/BaseBeneficiary';

const STATUS = {
	STARTED: 'Started',
	STOPPED: 'Stopped'
};

const COUNTDOWN = 60;

const SignInPage = ({
	actions,
	token,
	origin = 'login',
	generalReference,
	covidReferenceData,
	ios_app_link,
	android_app_link,
	operatingSystem
}) => {
	const router = useRouter();
	const canonicalURL =
		process.env.REACT_APP_DOMAIN_URL + (router?.asPath || router?.pathname);

	const [hash] = useState(router?.query?.id);
	const [contentValue, setContentValue] = useState({});
	const [openAlert, setOpenAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState('');
	const [signingForm, setSigningForm] = useState({ email: '', password: '' });
	const [formErrors, setFormErrors] = useState({});
	const [showLoading, setShowLoading] = useState(false);
	const [mailWasSend, setMailWasSend] = useState(false);
	const [formError, setFormError] = useState(null);

	const [disabledResend, setDisabledResend] = useState(true);
	const [statusTimer, setStatusTimer] = useState(STATUS.STOPPED);
	const [secondsRemaining, setSecondsRemaining] = useState(COUNTDOWN);

	/* Checking if the query string contains a refresh parameter and if it does, it will refresh the page. */
	useEffect(() => {
		const { query } = router;
		if (query.hasOwnProperty('refresh') && JSON.parse(query?.refresh)) {
			window.location.href = '/';
		}
	}, [router]);

	useInterval(
		() => {
			if (secondsRemaining > 0) {
				setSecondsRemaining(secondsRemaining - 1);
			} else {
				setDisabledResend(false);
				setStatusTimer(STATUS.STOPPED);
			}
		},
		statusTimer === STATUS.STARTED ? 1000 : null
	);

	useEffect(() => {
		if (mailWasSend) {
			handleStartCountDown();
		}
	}, [mailWasSend]);

	const handleStartCountDown = useCallback(() => {
		setSecondsRemaining(COUNTDOWN);
		setDisabledResend(true);
		setStatusTimer(STATUS.STARTED);
	}, []);

	// loader recaptcha
	useEffect(() => {
		(async () => {
			await loadRecaptchaV3();

			// login magic link
			let timer = null;
			const { token = null } = router.query;
			if (token) {
				setShowLoading(true);
				timer = setTimeout(() => {
					handleSignInEmail(false, token);
				}, 2000);
			}

			return () => {
				clearTimeout(timer);
			};
		})();
	}, [router, router.query]);

	useEffect(() => {
		validateSession();

		const tagManagerArgs = {
			dataLayer: {
				event: 'pageview',
				pageTitle:
					'asistensi es la mejor póliza de seguro para emergencias médicas'
			}
		};
		TagManager.dataLayer(tagManagerArgs);

		setContentValue(
			registryContent(origin, null, { android_app_link, ios_app_link })
		);
	}, []);

	useEffect(() => {
		actions.setMembershipOrigin('login');
		setContentValue(
			registryContent('login', null, { ios_app_link, android_app_link })
		);
	}, []);

	const validateSession = () => {
		if (token) return router.push('/');
	};

	const handleInput = async (event) => {
		const { name, value } = event.target;
		setSigningForm({ ...signingForm, [name]: value });

		const method = () =>
			name === 'password'
				? Validations.validateFieldPasswordOnlyCharacters(
						name,
						value,
						formErrors
				  )
				: Validations.validateFieldPassword(name, value, formErrors);

		const errors = method();

		setFormErrors({ ...errors });
		if (name === 'email' || name === 'code') {
			setFormError(null);
		}
	};

	const calculations = async (ben) => {
		for (const b of ben) {
			b.formErrors = [];
			b.age = _calculateAge(b.birth_date);

			const result = await actions.getPlansByAge({ age: b.age });
			if (result.products) b.products = result.products;
			if ((!b.questions || b.questions?.length === 0) && b.product_type) {
				b.questions = b.product_type.questions;
			}
		}
		return ben;
	};

	const handleClose = () => {
		setOpenAlert(false);
		setMessageAlert('');
	};

	const handleSignInSocial = (response) => {
		setShowLoading(true);
		getTokenReacaptcha(async (recaptcha) => {
			try {
				if (recaptcha) {
					const isGoogle = response.hasOwnProperty('googleId');

					Common.sign_in_social({
						provider: isGoogle ? 'google' : 'apple',
						tokenId: isGoogle
							? response.tokenId
							: response.authorization.id_token,
						recaptcha
					})
						.then(async (r) => {
							await handleSignResp(r);
						})
						.catch((e) => {
							setShowLoading(false);
							setOpenAlert(true);
							setMessageAlert(
								e.response && e.response.data
									? e.response.data.error?.message
									: 'Hubo un error al ingresar, intente nuevamente.'
							);
							if (
								e?.response?.data?.error?.message === 'Verifique el captcha.'
							) {
								loadRecaptchaV3();
							}
						});
				} else {
					setShowLoading(false);
					setOpenAlert(true);
					setMessageAlert('Captcha Requerido.');
				}
			} catch (error) {
				setShowLoading(false);
				setOpenAlert(true);
				setMessageAlert('Hubo un error al ingresar, intente nuevamente.');
			}
		});
	};

	// login with code
	const handleSignInEmail = (resend = false, token = null) => {
		setShowLoading(true);

		getTokenReacaptcha(async (recaptcha) => {
			const { email, code } = signingForm;

			// validate email field
			const errors = Validations.validateFieldPassword(
				'email',
				email,
				formErrors
			);
			setFormErrors({ ...errors });

			if (recaptcha) {
				// send code to user mail
				if (resend || (!mailWasSend && email && !formErrors.email)) {
					Common.send_code_to_email({ email, recaptcha, source: 'web' })
						.then(async (r) => {
							setMailWasSend(true);
							setShowLoading(false);
							setOpenAlert(true);
							setMessageAlert(
								r.message
									? r.message
									: 'A code has been sent to your email to log in.'
							);
							setFormError(null);
							// started timer
							handleStartCountDown();
						})
						.catch((e) => {
							setShowLoading(false);
							if (e?.response?.data?.error) {
								setFormError(e?.response?.data?.error?.message);
							}
							setOpenAlert(true);
							setMessageAlert(
								e.response && e.response.data
									? e.response.data.error.message
									: 'There was an error sending the request, please try again.'
							);
							if (e?.response?.data?.error?.message === 'Check the captcha.') {
								loadRecaptchaV3();
							}
						});
				} else if (
					(!resend &&
						mailWasSend &&
						email &&
						code &&
						!formErrors.email &&
						!formErrors.code) ||
					token
				) {
					setShowLoading(true);

					const sendData = {
						provider: 'email',
						recaptcha
					};

					if (token) {
						sendData.tokenId = token;
					} else {
						sendData.email = email;
						sendData.code = code;
					}

					Common.sign_in_social(sendData)
						.then(async (r) => {
							await handleSignResp(r);
						})
						.catch((e) => {
							setShowLoading(false);
							if (e?.response?.data?.error) {
								setFormError(e?.response?.data?.error?.message);
							}
							setOpenAlert(true);
							setMessageAlert(
								e.response && e.response.data
									? e.response.data.error.message
									: 'There was an error logging in, please try again.'
							);
							if (e?.response?.data?.error?.message === 'Verifique el captcha.')
								loadRecaptchaV3();
							else if (e?.response?.data?.error?.message.includes('Token'))
								window.history.replaceState(null, '', '/login');
						});
				} else {
					setShowLoading(false);
					setOpenAlert(true);
					setMessageAlert('Check the errors and fill in the required fields.');
				}
			} else {
				setShowLoading(false);
				setOpenAlert(true);
				setMessageAlert('Captcha Requerido, por favor intente de nuevo.');
				loadRecaptchaV3();
			}
		});
	};

	// login only password without code
	const signInWithPassword = () => {
		const { email, password } = signingForm;

		setShowLoading(true);

		getTokenReacaptcha(async (recaptcha) => {
			try {
				if (recaptcha) {
					Common.sign_in_social({
						provider: 'email',
						email,
						password,
						recaptcha
					})
						.then(async (r) => {
							await handleSignResp(r);
						})
						.catch((e) => {
							setShowLoading(false);
							setOpenAlert(true);
							setMessageAlert(
								e?.response?.data?.error?.message ||
									'There was an error logging in, please try again.'
							);
						});
				} else {
					setShowLoading(false);
					setOpenAlert(true);
					setMessageAlert('Captcha Requerido.');
				}
			} catch (error) {
				setShowLoading(false);
				setOpenAlert(true);
				setMessageAlert('There was an error logging in, please try again.');
			}
		});
	};

	const handleFailure = (err = null) => {
		console.error('err', err);
		if (err?.error === 'popup_closed_by_user' || !err) {
			setOpenAlert(true);
			setMessageAlert('Window closed, please try again.');
		} else if (
			err?.error === 'idpiframe_initialization_failed' &&
			err.details === 'Cookies are not enabled in current environment.'
		) {
			setOpenAlert(true);
			setMessageAlert(
				'Cookies are not enabled in your browser, please enable them to continue.'
			);
		} else if (err?.error === 'idpiframe_initialization_failed') {
			setOpenAlert(true);
			setMessageAlert('Invalid origin, please contact support.');
		}
	};

	//Function to Manage Response in SignIn
	const handleSignResp = async (r) => {
		let {
			_id,
			beneficiaries,
			customers,
			token,
			user_type,
			email,
			percent_off,
			coupon,
			free_trial,
			doc_dni,
			origin,
			is_client
		} = r.data;
		setShowLoading(false);
		await actions.setMembershipOrigin(origin);
		await actions.save_token({ token: token });
		await actions.save_user_type({ user_type: user_type });
		await actions.save_is_client({ is_client: is_client });
		await actions.save_userID({ userID: _id });
		const result = beneficiaries.find(
			(benefit) => benefit.kinship === 'TITULAR'
		);
		let titular;

		if (result) {
			titular = { ...BaseTitular, ...result };
			titular.include_titular = true;
			if (doc_dni) {
				titular.document_dni = 'Uploaded file';
				titular.doc_dni = doc_dni;
			}

			const age = _calculateAge(titular.birth_date);
			titular.age = age;

			const productsResult = await actions.getPlansByAge({ age });

			if (productsResult.products) titular.products = productsResult.products;

			if (
				(!titular.questions || titular.questions?.length === 0) &&
				titular.product_type
			) {
				titular.questions = titular.product_type.questions;
			}
		} else {
			const response = await actions.getTitularData();
			titular = { ...BaseTitular, ...response.titular };
			if (titular.doc_dni) {
				titular.document_dni = 'Uploaded file';
			} else if (doc_dni) {
				titular.document_dni = 'Uploaded file';
			}
			if (!titular.birth_date) titular.birth_date = null;
		}
		if (user_type === 100 || user_type === 110 || user_type === 111)
			actions.SignInally({
				...r.data,
				first_name: titular.first_name,
				last_name: titular.last_name,
				share_link: titular.share_link,
				share_link_discount: titular.share_link_discount
			});

		titular.nationality = !titular.nationality
			? titular.document_type
			: titular.nationality;
		await actions.handleStateAffiliation('titular', { ...titular });

		const beneficiariesUpdated = await calculations(beneficiaries);
		const allBeneficiaries = beneficiariesUpdated.map((b) =>
			BaseBeneficiary({ ...b })
		);
		await actions.handleStateAffiliation('beneficiaries', allBeneficiaries);
		await actions.handleStateAffiliation('customers', customers);
		await actions.save_titular_email({ email: email });

		if (percent_off)
			await actions.saveDiscountPercent({
				percent_off: percent_off,
				coupon: coupon,
				free_trial: free_trial,
				is_back_coupon: true
			});

		if (hash) {
			router.push(
				{
					pathname: '/affiliation',
					query: { is_profile: true, signIn: true, is_new_beneficiary: true }
				},
				'/start-registration'
			);
		} else if (
			parseInt(user_type) === 110 ||
			parseInt(user_type) === 100 ||
			parseInt(user_type) === 111
		) {
			router.push('/profile/ally/affiliations', '/profile/affiliates/members');
		} else if (
			router?.query?.type === 'sos-home' &&
			generalReference.motive_id !== 'default'
		) {
			if (
				r.data.first_name &&
				r.data.last_name &&
				r.data.birth_date &&
				r.data.sex &&
				r.data.dni
			) {
				const userData = {
					first_name: r.data.first_name,
					last_name: r.data.last_name,
					cell_phone: r.data.phone_one || '',
					dni: r.data.dni,
					birth_date: r.data.birth_date,
					sex: r.data.sex,
					email: r.data.email,
					country: r.data.country || '',
					city: r.data.city || ''
				};
				await actions.saveGeneralData({
					...generalReference,
					...userData,
					userID: titular._id,
					step: 'last'
				});

				router.push(
					'/profile/sos/general/result',
					'/profile/sos/consulta-general/resultados'
				);
			} else {
				await actions.saveGeneralData({
					...generalReference,
					email: r.data.email,
					step: 'datos'
				});
				router.push('/profile/sos', '/profile/sos/consulta-general/datos');
			}
		} else if (
			router?.query?.type === 'sos-covid' &&
			generalReference.motive_id === 'covid19'
		) {
			if (
				r.data.birth_date &&
				r.data.sex &&
				r.data.first_name &&
				r.data.last_name &&
				r.data.dni
			) {
				const userData = {
					full_name: r.data.first_name + ' ' + r.data.last_name,
					cell_phone: r.data.phone_one || '',
					dni: r.data.dni,
					age: _calculateAge(r.data.birth_date),
					sex: r.data.sex,
					email: r.data.email,
					country: r.data.country || '',
					city: r.data.city || ''
				};
				await actions.saveCovidData({
					...covidReferenceData,
					...userData,
					step: 'last'
				});
				router.push('/profile/sos/covid', '/profile/sos/covid-19/');
			} else {
				await actions.saveCovidData({
					...covidReferenceData,
					email: r.data.email,
					step: 'fourth'
				});
				router.push('/profile/sos/covid', '/profile/sos/covid-19/');
			}
		} else {
			router.push('/profile/beneficiaries', '/profile/covered-member');
		}
	};

	return (
		<LayoutContainer>
			<Head>
				<meta
					name="description"
					content="asistensi is the insurance for medical emergencies for the health care of your family, regardless of the distances"
				/>
				<title>
					asistensi is the best insurance policy for medical emergencies
				</title>
				<link rel="canonical" href={canonicalURL} />
			</Head>
			{showLoading && <SpinnerV />}
			<MembershipContent
				formType="signing"
				contentValue={contentValue}
				origin={origin}
				showText={true}
				ios_app_link={ios_app_link}
				android_app_link={android_app_link}
				isAppleDevice={operatingSystem === 'iOS'}
			>
				<>
					<div>
						<span className="text-pink-500 font-bold">Log in</span>{' '}
					</div>
					<div className="sm:border sm:border-gray-300 bg-white sm:p-5 mt-5 sm:mt-0">
						<SigningFormEmail
							onInput={handleInput}
							signingForm={signingForm}
							formErrors={formErrors}
							onSignInEmail={handleSignInEmail}
							mailWasSend={mailWasSend}
							setMailWasSend={setMailWasSend}
							formError={formError}
							disabledResend={disabledResend}
							secondsRemaining={secondsRemaining}
							statusTimer={statusTimer}
							signInWithPassword={signInWithPassword}
						/>
					</div>
				</>
			</MembershipContent>
			<SnackBar
				openAlert={openAlert}
				messageAlert={messageAlert}
				handleClose={handleClose}
			/>
		</LayoutContainer>
	);
};

const mapStateToProps = (state) => ({
	token: state.affiliation.get('token'),
	origin: state.membership.get('origin'),
	generalReference: state.sos19.get('generalReference'),
	covidReferenceData: state.sos19.get('covidReferenceData'),
	ios_app_link: state.affiliation.get('ios_app_link'),
	android_app_link: state.affiliation.get('android_app_link'),
	operatingSystem: state.profile.get('operatingSystem')
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
