import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { TextInput } from 'components/commons';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const SigningFormEmail = ({
	onInput,
	signingForm,
	formErrors,
	onSignInEmail,
	mailWasSend,
	formError,
	setMailWasSend,
	signInWithPassword,
	disabledResend,
	secondsRemaining,
	statusTimer
}) => {
	const [viewPass, setViewPass] = useState(false);
	const [isPassword, setIsPassword] = useState(true);
	const router = useRouter();

	const memoDisabled = useMemo(() => {
		// validate email and code
		if (signingForm?.email && signingForm?.code && mailWasSend) return false;

		// validate email and password
		if (
			!signingForm?.email ||
			!signingForm?.password ||
			((formErrors.hasOwnProperty('email') ||
				formErrors.hasOwnProperty('password')) &&
				!mailWasSend)
		) {
			return true;
		}
	}, [signingForm, formErrors, mailWasSend]);

	return (
		<>
			{mailWasSend && (
				<button
					onClick={() => {
						setMailWasSend(false);
						setIsPassword(true);
					}}
					type="button"
					className="mb-6 text-xs flex items-center text-gray-800 font-poppins cursor-pointer"
				>
					Back
				</button>
			)}

			{!mailWasSend && (
				<div className="pt-2 text-center">
					<TextInput
						name="email"
						label="Email Address"
						placeholder="Enter email Adress"
						helperText={formErrors?.email}
						errors={formErrors?.email}
						value={signingForm?.email}
						addClass="wInputFullNoMargin"
						onChange={onInput}
					/>

					<TextField
						name="password"
						label="Password"
						placeholder="Enter Password"
						type={viewPass ? 'text' : 'password'}
						helperText={formErrors?.password}
						variant="outlined"
						error={true}
						className="wInputFull mx-0 pb-3"
						InputLabelProps={{
							classes: {
								root:
									signingForm?.password && !formErrors?.password
										? 'cssLabel'
										: (!signingForm?.password && formErrors?.password) ||
										  formErrors?.password
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								root: !signingForm?.password
									? 'cssInput'
									: formErrors?.password
									? 'cssInputError'
									: 'cssInput',
								notchedOutline: !signingForm?.password
									? 'cssInput'
									: formErrors?.password
									? 'cssInputError'
									: 'cssInput'
							},
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={() => setViewPass(!viewPass)}
									>
										{!viewPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
									</IconButton>
								</InputAdornment>
							)
						}}
						margin="normal"
						value={signingForm?.password}
						required={true}
						onChange={(e) => onInput(e)}
					/>

					<a
						className="text-sm cursor-pointer text-pink-500 mt-6"
						onClick={() => {
							setIsPassword(false);
							onSignInEmail(false);
						}}
					>
						Continue without password
					</a>
				</div>
			)}

			{mailWasSend && (
				<div className="text-center text-base leading-relaxed text-gray-800">
					We have sent a temporary login code to <b>{signingForm?.email}</b>{' '}
					check your inbox.
				</div>
			)}

			{mailWasSend && (
				<div className="mt-8">
					<TextInput
						name="code"
						label="Code sent by mail"
						placeholder="Validation code"
						helperText={formErrors?.code || formError}
						errors={formErrors?.code || formError}
						value={signingForm?.code}
						addClass="wInputFullNoMarginCenter"
						onChange={onInput}
						maxLength={4}
					/>
				</div>
			)}

			{mailWasSend && (
				<>
					{statusTimer !== 'Stopped' ? (
						<div className="text-sm text-center">
							Wait{' '}
							<strong className="text-pink-500">{secondsRemaining} s</strong> to
							resend another code
						</div>
					) : (
						<div className="mt-0 text-center">
							<button
								className={
									'text-xs cursor-pointer tracking-extra text-pink-500 font-bold font-poppins uppercase'
								}
								onClick={() => onSignInEmail(true)}
								disabled={disabledResend}
							>
								Resend code
							</button>
						</div>
					)}
				</>
			)}

			<div className={'w-full items-center flex mt-5 justify-center'}>
				<button
					className="primary-button mt-2 min-w-70"
					onClick={() =>
						isPassword ? signInWithPassword() : onSignInEmail(false)
					}
					disabled={memoDisabled}
				>
					Log in
				</button>
			</div>
			<div className={'w-full items-center flex mt-5 justify-center'}>
				{!mailWasSend && (
					<button
						className="white-btn mt-2 min-w-70"
						onClick={() =>
							router.push(
								{
									pathname: '/freemium/Register'
								},
								'/join-asistensi-free'
							)
						}
					>
						I want to sign up to asistensi Free
					</button>
				)}
			</div>
		</>
	);
};

SigningFormEmail.propTypes = {
	onInput: PropTypes.func.isRequired,
	signingForm: PropTypes.object.isRequired,
	formErrors: PropTypes.object.isRequired,
	onSignInEmail: PropTypes.func.isRequired,
	disabledResend: PropTypes.bool.isRequired,
	secondsRemaining: PropTypes.number.isRequired,
	statusTimer: PropTypes.string.isRequired
};

SigningFormEmail.defaultProps = {
	mailWasSend: false,
	formError: null
};

export default SigningFormEmail;
